.dynamic-form-item--button:not(.leading-navigation-button) {
  text-transform: none;
}

.dynamic-form-item--button.text-link {
  align-self: flex-start;
  text-align: left;
}

.form-item--input-checkbox-text {
  white-space: normal;
}

.form-item--input-multi-select.form-item--input-multi-select {
  padding: 0;
}

.form-item--input-single-select.native-select-input,
.form-item--input-unitized .native-select-input {
  margin: 0;
}

.form-item--input-unitized {
  &.form-item--height {
    display: flex;
    gap: 4px;

    .select-input__view--mobile {
      width: 100%;
    }
  }
}
