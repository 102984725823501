@import "scss/main";

.dashboard-container {
  @include fadeIn();

  .contact-service {
    display: flex;
    margin: 48px auto 0;
    max-width: 860px;
    padding: 0;

    @include breakpoint(large) {
      justify-content: flex-start;
      margin-top: 64px;
    }
  }
}
