@import "scss/main";

.form-item-list-row {
  column-gap: 4px;
  display: grid;
  grid-template-columns: 1fr 1fr;

  &:has(.form-item-list-row__column:only-child) {
    grid-template-columns: 1fr;
  }
}

.form-item-list-row--clickable,
.form-item-list-row__column--clickable {
  cursor: pointer;
}

.form-item--space {
  .form-item-list-row & {
    grid-column-start: span 2;
  }
}

.form-item-list-row__column {
  align-items: center;
  display: grid;
  column-gap: 4px;
  grid-template-columns: 1fr;
  min-width: 44px;
}

.form-item-list-row__column--trailing {
  justify-items: end;
  justify-self: end;
}

.form-item-list-row__column--with-image {
  &.form-item-list-row__column--leading {
    grid-template-columns: min-content 1fr;
  }

  &.form-item-list-row__column--trailing {
    grid-template-columns: 1fr min-content;
  }
}

.form-item-list-row-column__texts {
  .form-item--text {
    .form-item-list-row__column--trailing & {
      text-align: right;
    }

    &:not(:last-child) {
      margin-bottom: 4px;
    }
  }
}

.form-item-list-row__divider {
  grid-column-start: span 2;

  // Selector looks for form-item-list-row that is not followed by another form-item-list-row
  .form-item-list-row:not(:has(~ .form-item-list-row)) & {
    display: none;
  }
}
