@import "scss/main";

.payment-confirmation {
  @include fadeIn();

  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;

  @include breakpoint(medium) {
    margin: 0 auto;
    max-width: 450px;
  }

  @include breakpoint(large) {
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    max-width: 960px;
  }
}

.payment-confirmation-congratulations {
  margin-bottom: 24px;
  position: relative;

  @include breakpoint(medium) {
    margin-bottom: 32px;
  }

  @include breakpoint(large) {
    flex-basis: calc(50% - 30px);
    margin-bottom: 76px;
    margin-right: 60px;
  }

  /* Background image shenangigans below */
  &::before {
    background-image: url("../../assets/images/blobs/BlogGray.svg");
    background-repeat: no-repeat;
    content: "";
    height: 176px;
    left: -100px;
    position: absolute;
    top: -21px;
    width: 169px;
    z-index: -30;

    @include breakpoint(medium) {
      top: -59px;
    }
  }
}

.payment-confirmation-congratulations__header {
  margin-bottom: 16px;

  @include breakpoint(medium) {
    margin-bottom: 24px;
  }
}

.payment-confirmation-congratulations__welcome-message {
  color: color("secondary-text");
}

.payment-confirmation-policy-details {
  margin-bottom: 24px;

  @include breakpoint(medium) {
    margin-bottom: 32px;
  }

  @include breakpoint(large) {
    flex-basis: calc(50% - 30px);
    margin-bottom: 76px;
  }
}

.payment-confirmation-policy-details__header {
  margin-bottom: 24px;
}

.payment-confirmation-policy-details__list.payment-confirmation-policy-details__list {
  margin-bottom: 24px;
  max-width: 100%;
}

.payment-confirmation-policy-details__list-item.payment-confirmation-policy-details__list-item {
  max-width: 100%;

  &:last-child {
    border-bottom-width: 0;
  }
}

.payment-confirmation-policy-details__cta {
  width: 100%;

  @include breakpoint(medium) {
    margin: 0 auto;
    max-width: 350px;
  }
}

.payment-confirmation-customer-support {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    align-items: center;
  }

  @include breakpoint(large) {
    max-width: 450px;
  }
}

.payment-confirmation-customer-support__header {
  margin-bottom: 16px;

  @include breakpoint(medium) {
    margin-bottom: 32px;
  }
}

.payment-confirmation-customer-support__cta {
  align-items: center;
  background-color: inherit;
  border: 0;
  color: color("cta");
  cursor: pointer;
  display: flex;
  margin-bottom: 16px;
  padding: 0;

  > :not(:last-child) {
    margin-right: 16px;
  }

  @include breakpoint(medium) {
    flex-direction: column;
    margin-bottom: 24px;

    > :not(:last-child) {
      margin-bottom: 16px;
      margin-right: 0;
    }
  }
}

.payment-confirmation-customer-support__additional-info {
  color: color("secondary-text");

  @include breakpoint(medium) {
    text-align: center;
  }
}
